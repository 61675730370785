
import { TYPES } from '@/core/config/Types';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import WarehouseAdministrationCenter from '@/wms/infrastructure/ui/warehouseCenter/WarehouseAdministrationCenter.vue';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppDashboardDefault',
  components: { WarehouseAdministrationCenter }
})
export default class AppDashboardDefault extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;

  isWms = false;
  isLoading = false;

  created() {
    this.findCompany();
  }

  async findCompany() {
    const companyId = localStorage.getItem('businessId');
    if (!companyId) return;
    const res: any = await this.findByIdCompany.execute(companyId);
    this.isWms = res.isWms;
  }
}
